const state = {
  //config url
  vlive_config: "https://vlive-config.firebaseio.com/preprod/web/vlive/v1.json?print=pretty",
  net5_config: "https://vlive-config.firebaseio.com/prod/web/net5/v1.json",
  nammaflix_config: "https://vlive-config.firebaseio.com/prod/web/nammaflix/v1.json",
  // noorplay_config: "https://vlive-config.firebaseio.com/prod/web/noorplay/v1.json",

  noorplay_test: "https://noorplay-web-application-default-rtdb.firebaseio.com/uj-betav1.json",
  //"https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-device-listing.json",

  noorplay_beta: "https://noorplay-web-application-default-rtdb.firebaseio.com/nooeplay-beta-latest.json", // Test - Latest

	noorplay_config: 
		 "https://d2xowqqrpfxxjf.cloudfront.net/ultragaane/web-prod-config-v1.json",
    //  "https://ultra-gaane-90ebe-default-rtdb.firebaseio.com/ultraganne.json",

  // decking_config: "https://d27kvsajbyluqu.cloudfront.net/deckingconfig/ultrajhs/trjOLRlT.json",    
  FIRE_FCM_SERVER_KEY:
	// "AAAAAKmmC_0:APA91bEwGOZ8D0IEHXKku0GfVWEqU39D6TZYO91a3VxPI0Lx_DIAQfyuW5XnW-RV1lAZcQhRITVy-lzg4jjODe4dVP39vUNKamH6l4XPKouv7uaKcD5xb3FuA5e0MjnRwObjYB5HT05v", // - Test

    "AAAArYjDz_s:APA91bErpX8I8QtHoYb8zFQjRwASvZsiq4U-wKKjNbpxg1pGWkUggIKemtr9LyP9nXJz_4wXUPyMpbxOYO5pV4MdfwVD4lzKB0xsmHiJiGtXHbNYTkDHgdh74putcvGTfgx3TvkdiaAt", // PROD

  FIRE_FCM_CERTIFICATE_KEY:
    // "BPYcUWM7Ojk0nP-ErERbkoeFo7soLgQBDfXAb6apGURteoXq0sKUofRl0KdTIYw_C36ynJJeRIfalseeheOssiw", // - Test

    "BJobUtAl7lsoUrITGMTOnFbi_ErvI5yZfY7bxmJDLDwmszQ_MM6TfeWIUq1jg_bscA61NShGwEczPXHjRcM1FJ4", // PROD

	//vlive preprod firebase app config
  FIRE_APP_ID: "1:657699626385:web:64f028b74d1d70da814e30",
	FIRE_API_KEY: "AIzaSyAZq-CNdMd8JZN7L3DegwqARQjAgegqwkQ",
	FIRE_AUTH_DOMAIN: "ultra-gaane.firebaseapp.com",
	FIRE_DATABASE_URL: "https://ultra-gaane-default-rtdb.asia-southeast1.firebasedatabase.app",
	FIRE_PROJECT_ID: "ultra-gaane",
	FIRE_STORAGE_BUCKET: "ultra-gaane.appspot.com",
	FIRE_MESSAGING_SENDER_ID: "657699626385",
	FIRE_MEASUREMENT_ID: "G-T8VPF3B9XX",

	//noorplay firebase app config
  FIRE_APP_ID: "1:657699626385:web:64f028b74d1d70da814e30",
	FIRE_API_KEY: "AIzaSyAZq-CNdMd8JZN7L3DegwqARQjAgegqwkQ",
	FIRE_AUTH_DOMAIN: "ultra-gaane.firebaseapp.com",
	FIRE_DATABASE_URL: "https://ultra-gaane-default-rtdb.asia-southeast1.firebasedatabase.app",
	FIRE_PROJECT_ID: "ultra-gaane",
	FIRE_STORAGE_BUCKET: "ultra-gaane.appspot.com",
	FIRE_MESSAGING_SENDER_ID: "657699626385",
	FIRE_MEASUREMENT_ID: "G-T8VPF3B9XX",
};

const getters = {
  //config urls
  vlive_config: (state) => {
    return state.vlive_config;
  },

  noorplay_config: (state) => {
    return state.noorplay_config;
  },
  decking_config: (state) => {
    return state.decking_config;
  },
  noorplay_beta: (state) => {
    return state.noorplay_beta;
  },
  noorplay_test: (state) => {
    return state.noorplay_test;
  },

  //vlive firebase config
  FIRE_APP_ID: (state) => {
    return state.FIRE_APP_ID;
  },
  FIRE_API_KEY: (state) => {
    return state.FIRE_API_KEY;
  },
  FIRE_AUTH_DOMAIN: (state) => {
    return state.FIRE_AUTH_DOMAIN;
  },
  FIRE_DATABASE_URL: (state) => {
    return state.FIRE_DATABASE_URL;
  },
  FIRE_PROJECT_ID: (state) => {
    return state.FIRE_PROJECT_ID;
  },
  FIRE_STORAGE_BUCKET: (state) => {
    return state.FIRE_STORAGE_BUCKET;
  },
  FIRE_MESSAGING_SENDER_ID: (state) => {
    return state.FIRE_MESSAGING_SENDER_ID;
  },
  FIRE_MEASUREMENT_ID: (state) => {
    return state.FIRE_MEASUREMENT_ID;
  },

  //net5 firebase config
  FIRE_APP_ID_2: (state) => {
    return state.FIRE_APP_ID_2;
  },
  FIRE_API_KEY_2: (state) => {
    return state.FIRE_API_KEY_2;
  },
  FIRE_AUTH_DOMAIN_2: (state) => {
    return state.FIRE_AUTH_DOMAIN_2;
  },
  FIRE_DATABASE_URL_2: (state) => {
    return state.FIRE_DATABASE_URL_2;
  },
  FIRE_PROJECT_ID_2: (state) => {
    return state.FIRE_PROJECT_ID_2;
  },
  FIRE_STORAGE_BUCKET_2: (state) => {
    return state.FIRE_STORAGE_BUCKET_2;
  },
  FIRE_MESSAGING_SENDER_ID_2: (state) => {
    return state.FIRE_MESSAGING_SENDER_ID_2;
  },
  FIRE_MEASUREMENT_ID_2: (state) => {
    return state.FIRE_MEASUREMENT_ID_2;
  },

  //nammaflix firebase config
  FIRE_APP_ID_3: (state) => {
    return state.FIRE_APP_ID_3;
  },
  FIRE_API_KEY_3: (state) => {
    return state.FIRE_API_KEY_3;
  },
  FIRE_AUTH_DOMAIN_3: (state) => {
    return state.FIRE_AUTH_DOMAIN_3;
  },
  FIRE_DATABASE_URL_3: (state) => {
    return state.FIRE_DATABASE_URL_3;
  },
  FIRE_PROJECT_ID_3: (state) => {
    return state.FIRE_PROJECT_ID_3;
  },
  FIRE_STORAGE_BUCKET_3: (state) => {
    return state.FIRE_STORAGE_BUCKET_3;
  },
  FIRE_MESSAGING_SENDER_ID_3: (state) => {
    return state.FIRE_MESSAGING_SENDER_ID_3;
  },
  FIRE_MEASUREMENT_ID_3: (state) => {
    return state.FIRE_MEASUREMENT_ID_3;
  },

  //noorplay firebase config
  FIRE_APP_ID_4: (state) => {
    return state.FIRE_APP_ID_4;
  },
  FIRE_API_KEY_4: (state) => {
    return state.FIRE_API_KEY_4;
  },
  FIRE_AUTH_DOMAIN_4: (state) => {
    return state.FIRE_AUTH_DOMAIN_4;
  },
  FIRE_DATABASE_URL_4: (state) => {
    return state.FIRE_DATABASE_URL_4;
  },
  FIRE_PROJECT_ID_4: (state) => {
    return state.FIRE_PROJECT_ID_4;
  },
  FIRE_STORAGE_BUCKET_4: (state) => {
    return state.FIRE_STORAGE_BUCKET_4;
  },
  FIRE_MESSAGING_SENDER_ID_4: (state) => {
    return state.FIRE_MESSAGING_SENDER_ID_4;
  },
  FIRE_MEASUREMENT_ID_4: (state) => {
    return state.FIRE_MEASUREMENT_ID_4;
  },

  //clevertap login event consts.
  net5_login_Name: (state) => {
    return state.net5_login_Name;
  },
  net5_login_Identity: (state) => {
    return state.net5_login_Identity;
  },
  net5_login_Email: (state) => {
    return state.net5_login_Email;
  },
  net5_login_Phone: (state) => {
    return state.net5_login_Phone;
  },
  net5_login_Gender: (state) => {
    return state.net5_login_Gender;
  },
  net5_login_DOB: (state) => {
    return state.net5_login_DOB;
  },
  net5_login_SubscriberId: (state) => {
    return state.net5_login_SubscriberId;
  },
  net5_login_MSG_EMAIL: (state) => {
    return state.net5_login_MSG_EMAIL;
  },
  net5_login_MSG_PUSH: (state) => {
    return state.net5_login_MSG_PUSH;
  },
  net5_login_MSG_SMS: (state) => {
    return state.net5_login_MSG_SMS;
  },

  FIRE_FCM_SERVER_KEY: (state) => {
    return state.FIRE_FCM_SERVER_KEY;
  },

  FIRE_FCM_CERTIFICATE_KEY: (state) => {
    return state.FIRE_FCM_CERTIFICATE_KEY;
  },
};

export const consts = { state, getters };
