let _fbId = null;
let _googleId = null;
let _clientKey = null;
let _providerId = null;
let _firebase = null;
let _projectName = null;

export default (providerId) => {
  console.log("providerId", providerId);
  switch (providerId) {

		case "ultragaa":
			//prod provider id : ultrajhs
			//clientkey: VrT02swZ6SUXYQBL
			//providerkey: JFmM5gZMAFXWLiHt
			//-----beta
			//beta provider id : ultraind
			//clientkey: 1gFx7yVUNs43vfB5
			//providerkey: ""u
			// ****************** betav1 **********

			// ----- Noorplay provider ----

			// _providerId = providerId;
			// _clientKey = "kcnRb0689wtL8RUn";

			// ----- Noorplay provider - end ----

			// ----- Manishkr provider ----

			// _providerId = "manishkr";
			// _clientKey = "M1jV9g8g8SuLmoKp";

			// ----- Noorplay provider - end ----

			// PROD - Google and Facebook creds for login - TEST
			// _fbId = "408552338100171";
			// _googleId = "745323876347-so4f0uqbvnosbvfifpjrt0o91gtbvedp.apps.googleusercontent.com";
			// _firebase = "preprod";

			// ***************** betav1 ***********

			// ***************** prod *************
			_providerId = providerId;
			_clientKey = "ql9t6YZLAQuZ0ozG";

			_fbId = "695632792546539";
			_googleId = "657699626385-orr5n7v87h3spuam86nlbt6klsbuc8mo.apps.googleusercontent.com";
			_firebase = "preprod";

			// _firebase = "preprod";
			// _firebase = "preprodPOC";
			// *************** prod ****************

			_projectName = "preprod";
			// window.document.title = "Noor Play - unlimited TV shows and more";
			// document
			//   .getElementById("favicon")
			//    .setAttribute("href", require("@/assets/favIcons/Noorplayfav.svg"));
			break;
    case "noorplay":
      // ****************** betav1 **********

      // ----- Noorplay provider ----

      // _providerId = providerId;
      // _clientKey = "kcnRb0689wtL8RUn";

      // ----- Noorplay provider - end ----

      // ----- Manishkr provider ----

      // _providerId = "manishkr";
      // _clientKey = "M1jV9g8g8SuLmoKp";

      // ----- Noorplay provider - end ----

      // PROD - Google and Facebook creds for login - TEST
      // _fbId = "147575555971331";
      // _googleId = "745323876347-so4f0uqbvnosbvfifpjrt0o91gtbvedp.apps.googleusercontent.com";
      // _firebase = "preprod";

      // ***************** betav1 ***********

      // ***************** prod *************
      _providerId = providerId;
      _clientKey = "btJ85rtEsEhyrE0t";

      _fbId = "147575555971331";
      _googleId = "745323876347-so4f0uqbvnosbvfifpjrt0o91gtbvedp.apps.googleusercontent.com";
      _firebase = "noorplay";

      // _firebase = "preprod";
      // _firebase = "preprodPOC";
      // *************** prod ****************

      _projectName = "Noor Play";
      // window.document.title = "Noor Play - unlimited TV shows and more";
      // document
      //   .getElementById("favicon")
      //    .setAttribute("href", require("@/assets/favIcons/Noorplayfav.svg"));
      break;
  }
};

export { _providerId, _googleId, _fbId, _clientKey, _firebase, _projectName };
