import { configClass } from "@/modules/configClass.js";

import { userClass } from "@/modules/userClass.js";
import { eventBus } from "@/eventBus";
import { encryptDeviceInfo } from "./../utilities";

import { store } from "./store";

let refresh_token = false;

window.reLoginRequired = false;

export const makeQueryStringDync = (dataObj) => {
  let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails")
            ? JSON.parse(localStorage.getItem("subscriberProfileDetails"))
            : null;
  let selectedLanguages = subscriberProfileDetails?.preferredlanguage || [];
  if (localStorage.getItem("sessionToken")) {
    dataObj["preferredlanguage"] = JSON.stringify(selectedLanguages);
  }
  if (!Object.keys(dataObj).length) return false;

  let queryStr = "?";
  for (let item in dataObj) {
    queryStr += `${item}=${(dataObj[item])}&`;
  }
  queryStr = queryStr.substring(0, queryStr.length - 1);
  return queryStr;
};

export const stringifyCaptchaParams = (obj) => {
  if (Object.keys(obj).length <= 0) return;

  let queryStr = "?";

  for (let item in obj) {
    queryStr += `captcha[${item}]=${obj[item]}&`;
  }

  queryStr = queryStr.substring(0, queryStr.length - 1);

  return queryStr;
};

export const makeQueryFormDataDyn = (dataObj) => {
  if (!Object.keys(dataObj).length) return false;
  let obj = new FormData();
  for (let data in dataObj) {
    obj.append(data, dataObj[data]);
  }
  return obj;
};

export const errorServiceHandler = (response) => {
  if ((response.status == 200 || response.status == 201) && response.ok) {
    return response.json();
  } else {
    return response.json();
  }
};

export const paymentRedirect = (response) => {
  if ((response.status == 200 || response.status == 201) && response.ok) {
    return response.json();
  } else if (response.status >= 300 && response.status <= 399) {
    window.open(response.header.location, "_self");
  } else {
    return response.json();
  }
};

export const toFormUrlEncoded = (object) => {
  return Object.entries(object)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");
};

const registerDevice = (counter, callback) => {
  actNewDeviceRegister()
    .then((data) => {
      if (!data.errorcode) {
        refresh_token = false;
        if (!store.getters.subscriberid) {
          store.commit("setUnauthorizedErrorOccured", false);
        }

        if (window.reLoginRequired == true) {
          window.reLoginRequired = false;
          let login_payload = localStorage.getItem("login-payload");
          login_payload = login_payload ? JSON.parse(login_payload) : null;
          login_payload.autologin = true;
          login_payload.deviceos = getDeviceOS();
        
          const currentProfileId = localStorage.getItem("currentProfileId");
          if(currentProfileId) {
            login_payload.params.profileid = currentProfileId  
          }

          eventBus.$emit("subscriberLogin", login_payload);

          eventBus.$on("login-response", (data) => {
            if (data.success) {
              localStorage.setItem("sessionToken", data.success);
            }

            store.commit("setUnauthorizedErrorOccured", false);

            let lang = localStorage.getItem("setDisplayLanguageCode");
            eventBus.$emit("check-for-series-route", lang);

            eventBus.$emit("re-login-success", true);

            setTimeout(() => {
              eventBus.$emit("rerender-template");
            }, 1000);

            eventBus.$off("login-response");
          });
        }
      } else if (data.errorcode == 6055 && counter > 0) {
        store.commit("setUnauthorizedErrorOccured", false);

        registerDevice(counter - 1);
      } else if (data.errorcode == 8601) {

        if (localStorage.getItem("sessionToken")) {
          // eventBus.$emit("subscriberLogout");
        }

      } else {
        store.commit("setUnauthorizedErrorOccured", false);

        refresh_token = false;
        window.reLoginRequired = false;   
      }
    })
    .catch((error) => {
      window.reLoginRequired = false;
      refresh_token = false;
      // eventBus.$emit("subscriberLogout");
      // store.commit("setUnauthorizedErrorOccured", false);


    });
};

export const actTokenRefresh = (callback) => {
  let isAnonymousUser = localStorage.getItem("subscriberDetails") ? false : true;

  // if (!isAnonymousUser) {
  //   store.dispatch("actRefreshToken").then((tokenResponse) => {

  //   });
  // }

  // If anonymous user call device register api to get new device token.
  if (isAnonymousUser == true || window.reLoginRequired == true) {
    if (!refresh_token) {
      refresh_token = true;
      registerDevice(2, callback);
    }
  }
  //If not anonymous user call login api to get new session token.
  else if (isAnonymousUser == false) {
    console.log("Inside non anonymous user type");

    window.reLoginRequired = true;
    store.dispatch("actRefreshToken").then((tokenResponse) => {
      if(tokenResponse.refreshtoken) {
        store.commit("setUnauthorizedErrorOccured", false);

        localStorage.setItem("sessionToken", tokenResponse.success);
        localStorage.setItem("refreshtoken", tokenResponse.refreshtoken);
        window.location.reload();
      } else {
        if (localStorage.getItem("sessionToken")) {
          eventBus.$emit("subscriberLogout");
        }
      }
    
    }).catch((error) => {
      // if (localStorage.getItem("sessionToken")) {
      //   eventBus.$emit("subscriberLogout");
      // }
    })

    // registerDevice(2, callback);
  }
};

export const errorHandlerMiddleware = (response) => {
  if (!response.ok) {
    return response
      .json()
      .catch(() => {
        throw response;
      })
      .then((data) => {
        data.statusCode = response.status;
        if (response.status == 401) {
          store.commit("setUnauthorizedErrorOccured", true);

          // Function to get new Token on 401 status code.
          actTokenRefresh();
        }
        return data;
      });
  }
  //return json response if request is successfull.
  return response.json();
};

const actNewDeviceRegister = () => {
  return new Promise((resolve, reject) => {
    encryptDeviceInfo()
      .then((response) => {
        store.dispatch("deviceRegister", response).then((response) => {
          if (!response.errorcode) {
            window.activeToken = true;
            store.commit("setToken", response.success);
            localStorage.setItem("deviceToken", response.success);
            let lang = localStorage.getItem("setDisplayLanguageCode");
            eventBus.$emit("check-for-series-route", lang);
            eventBus.$emit("featured-carousel");
          }
          resolve(response);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setHeader = (contentType = null, type = null) => {
  const header = {};

  if (contentType && contentType === "json") {
    header["Content-Type"] = "application/json";
  } else if (contentType === "formUrl") {
    header["Content-Type"] = "application/x-www-form-urlencoded";
  }

  if (localStorage.getItem("sessionToken") && type != "deviceToken") {
    header["X-SESSION"] = `${localStorage.getItem("sessionToken")}`;
  } else {
    header["Authorization"] = `Bearer ${localStorage.getItem("deviceToken")}`;
  }

  return header;
};

export const getDeviceOS = () => {
  let deviceOS = "";

  if (navigator.userAgent.indexOf("Win") != -1) {
    deviceOS = "WINDOWS";
  }

  if (navigator.userAgent.indexOf("Macintosh") != -1) {
    // deviceOS = "MACOS";
    deviceOS = "WINDOWS";
  }

  if (navigator.userAgent.indexOf("Linux") != -1) {
    // deviceOS = "LINUX";
    deviceOS = "WINDOWS";
  }

  if (navigator.userAgent.indexOf("Android") != -1) {
    // deviceOS = "ANDROID";
    deviceOS = "WINDOWS";
  }

  if (navigator.userAgent.indexOf("like Mac") != -1) {
    // deviceOS = "MACOS";
    deviceOS = "WINDOWS";
  }

  if (!deviceOS) {
    // deviceOS = "OTHER";
    deviceOS = "WINDOWS";
  }

  return deviceOS;
};
